import React, { Component } from 'react';
import './AppFooter.css';

class AppFooter extends Component {
  render() {
    return (
      <footer>
          <a className="copyright" href="https://nic-place.com/de">powered by NIC-place</a>
      </footer>
    );
  }
}

export default AppFooter;
